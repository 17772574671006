import styles from './index.module.css';

/**
 * Renders the label, border with gradient color and
 * error message for a given child input.
 * Other supplied props will be given to the outermost element.
 * The component provides a `.input` class to import from
 * its `index.module.css` and easily style the wrapped input element.
 * @param {object} props The props of the component.
 * @param {string} [props.label] The text for the label.
 * @param {boolean} [props.showError] A boolean indicating whether to show or
 * not the given error message.
 * @param {string} [props.errorMessage] The error message to show.
 */
const InputWrapper = ({
  children,
  label = 'Ingresa un valor',
  showError = false,
  errorMessage = 'El campo tiene un error.',
  className = '',
  ...restProps
}) => {
  return (
    <label className={styles.container + ' ' + className} {...restProps}>
      <span className={styles.label}>{label}</span>
      <div className={styles.inputBorder + ' ' + (showError ? styles.hasError : '')}>
        {children}
      </div>
      {showError && <strong className={styles.error}>{errorMessage}</strong>}
    </label>
  );
};

export default InputWrapper;
