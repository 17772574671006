import { Icon } from '@iconify/react';
import styles from './index.module.css';
import { format as formatRut } from 'rut.js';

const LockedRutInput = ({ rut, className = '' }) => {
  return (
    <p className={styles.container + ' ' + className}>
      <span className={styles.label}>
        <Icon
          className={styles.icon}
          width={24}
          height={24}
          icon={'mdi:card-account-details-outline'}
        />{' '}
        Tu RUT
      </span>
      <span className={styles.rut}>{formatRut(rut)}</span>
    </p>
  );
};

export default LockedRutInput;
