import ModalImg from '../../assets/modal-img.svg';
import LockIcon from '../../assets/lock-icon-blue.svg';
import ShieldIcon from '../../assets/shield-icon.svg';
import './index.css';

const GobCredentialsModal = ({ isOpen, close }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <>
      <div onClick={close} className="gob-credentials-modal__background">
        {/* Stop the propagation of click events to avoid closing the modal
            when it gets clicked. */}
        <div className="gob-credentials-modal__container" onClick={(e) => e.stopPropagation()}>
          <img src={ModalImg} className="gob-credentials-modal__image" alt="" />
          <h3 className="gob-credentials-modal__title">Seguridad y claves de acceso</h3>
          <p className="gob-credentials-modal__text">
            Utilizamos esta clave solo para procesos de consulta y{' '}
            <strong>no guardamos ningún dato</strong>.
          </p>
          <div className="gob-credentials-modal__highlight">
            <div className="gob-credentials-modal__highlight-line">
              <img src={LockIcon} className="gob-credentials-modal__highlight-icon" alt="" />
              No guardamos tus claves
            </div>
            <div className="gob-credentials-modal__highlight-line">
              <img src={ShieldIcon} className="gob-credentials-modal__highlight-icon" alt="" />
              No guardamos tus datos
            </div>
          </div>
          <button onClick={close} className="button gob-credentials-modal__button">
            Me queda claro
          </button>
        </div>
      </div>
    </>
  );
};
export default GobCredentialsModal;
