import { UserDataContext, PATHS } from '../../App';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import InformationalBanner from '../InformationalBanner';
import InputWrapper from '../InputWrapper';
import inputWrapperStyles from '../InputWrapper/index.module.css';
import NavigationButtons from '../NavigationButtons';
import { IdentificationIcon } from '@heroicons/react/outline';
import styles from './index.module.css';

const DependentsSection = () => {
  const { userData, setUserData } = useContext(UserDataContext);
  const { dependents_number } = userData.personal_info;
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid }
  } = useForm({ mode: 'onChange' });
  const navigate = useNavigate();
  const onSubmit = (data) => {
    setUserData({
      ...userData,
      personal_info: {
        ...userData.personal_info,
        dependents_number: data.dependents
      }
    });
    navigate(PATHS.DWELLING);
  };

  return (
    <section className={styles.container}>
      <NavigationButtons className={styles.navigationButtons} previousPath={PATHS.MARITAL_STATUS} />

      <InformationalBanner
        title="Información personal"
        text={`Completa el formulario con tu información personal para
               poder rellenar tu estado de situación.`}
        Icon={IdentificationIcon}
      />

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper
          className={styles.inputWrapper}
          label="Número de cargas familiares"
          showError={formErrors.dependents?.message}
          errorMessage={formErrors.dependents?.message}
        >
          <input
            className={inputWrapperStyles.input}
            type="number"
            defaultValue={dependents_number}
            placeholder="Ingresa un número"
            {...register('dependents', {
              required: 'Se requiere ingresar un número.',
              valueAsNumber: true,
              min: {
                value: 0,
                message: 'Debe ser un número mayor o igual a 0.'
              }
            })}
          />
        </InputWrapper>

        <button className={styles.button + ' button'} disabled={!isValid}>
          Continuar
        </button>
      </form>
    </section>
  );
};

export default DependentsSection;
