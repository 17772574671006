import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PageHeader from './components/PageHeader';
import ContactDataSection from './components/ContactDataSection';
import ProfessionSection from './components/ProfessionSection';
import BankSection from './components/BankSection';
import ValidationSection, { EntityTypes } from './components/ValidationSection';
import ClaveUnicaSection from './components/ClaveUnicaSection';
import ConfirmationSection from './components/ConfirmationSection';
import DownloadSection from './components/DownloadSection';
import DependentsSection from './components/DependentsSection';
import SelectionStep from './components/SelectionStep';
import { IdentificationIcon, LibraryIcon } from '@heroicons/react/outline';
import { ReactComponent as BCIIcon } from './assets/banks/bci.svg';
import { ReactComponent as BiceGreyIcon } from './assets/banks/bice-grey.svg';
import { ReactComponent as ChileIcon } from './assets/banks/chile.svg';
import { ReactComponent as ConsorcioGreyIcon } from './assets/banks/consorcio-grey.svg';
import { ReactComponent as EstadoIcon } from './assets/banks/estado.svg';
import { ReactComponent as FalabellaGreyIcon } from './assets/banks/falabella-grey.svg';
import { ReactComponent as ItauIcon } from './assets/banks/itau.svg';
import { ReactComponent as RipleyGreyIcon } from './assets/banks/ripley-grey.svg';
import { ReactComponent as SantanderIcon } from './assets/banks/santander.svg';
import { ReactComponent as ScotiaIcon } from './assets/banks/scotia.svg';
import { ReactComponent as SecurityGreyIcon } from './assets/banks/security-grey.svg';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const PUBLIC_URL = process.env.PUBLIC_URL || undefined;

// Use this for paths instead of writing strings everywhere.
export const PATHS = {
  CONTACT_DATA: '/',
  PROFESSION: '/profession',
  BANK: '/bank-form',
  CLAVE_UNICA: '/clave-unica-form',
  BANK_VALIDATION: '/bank-validation',
  CLAVE_UNICA_VALIDATION: '/clave-unica-validation',
  DATA_CONFIRMATION: '/data-confirmation',
  EXCEL_DOWNLOAD: '/excel-download',
  MARITAL_STATUS: '/marital-status',
  MATRIMONIAL_REGIME: '/matrimonial-regime',
  DEPENDENTS_NUMBER: '/dependents-number',
  EDUCATIONAL_LEVEL: '/educational-level',
  JOB_SITUATION: '/job-situation',
  DWELLING: '/dwelling',
  SELECT_BANK: '/select-bank'
};

// Used by PageHeader to help show the progress of the
// process.
const sectionsOrder = [
  PATHS.CONTACT_DATA,
  PATHS.MARITAL_STATUS,
  PATHS.MATRIMONIAL_REGIME,
  PATHS.DEPENDENTS_NUMBER,
  PATHS.DWELLING,
  PATHS.EDUCATIONAL_LEVEL,
  PATHS.JOB_SITUATION,
  PATHS.PROFESSION,
  PATHS.SELECT_BANK,
  PATHS.BANK,
  PATHS.CLAVE_UNICA,
  PATHS.DATA_CONFIRMATION
];

// Paths for which the header should not show
// the progress bar.
const noProgressBarPaths = [
  PATHS.BANK_VALIDATION,
  PATHS.CLAVE_UNICA_VALIDATION,
  PATHS.EXCEL_DOWNLOAD
];

export const UserDataContext = React.createContext();

function App() {
  // userData will contain all the fields to send to
  // the server at the end of the flux, to generate
  // the estado-situación excel.
  // See the table of fields in backend/README.md.
  const [userData, setUserData] = useState({
    default_username: undefined,
    bank_name: undefined,
    bank_username: undefined,
    bank_password: undefined,
    clave_unica_username: undefined,
    clave_unica_password: undefined,
    sii_username: '',
    sii_password: '',
    personal_info: {
      rut: undefined,
      name: undefined,
      email: undefined,
      marital_status: undefined,
      matrimonial_regime: '',
      dependents_number: undefined,
      dwelling: undefined,
      phone: '',
      nationaly: '',
      sex: '',
      birth_date: '',
      dwelling_year: 0,
      home_address: '',
      commercial_address: ''
    },
    occupational_info: {
      job_situation: undefined,
      educational_level: undefined,
      profession: undefined,
      company: {
        type: undefined,
        name: '',
        rut: '',
        relationship: '',
        occupational_level: '',
        position: '',
        start_date: ''
      },
      educational_institution: '',
      user_monthly_income: '',
      previous_company: {
        name: '',
        rut: '',
        position: '',
        time_spent: ''
      }
    },
    spouse_info: {
      rut: '',
      name: '',
      phone: '',
      email: '',
      educational_level: '',
      profession: '',
      monthly_income: ''
    }
  });

  // credentialsToVerify will contain credentials of
  // the bank, CMF and SII that wait for verification.
  // It will have the information to send to the /identity-validation
  // endpoint of the backend, and has the following form:
  // {
  //   entity: clave_unica, sii, cmf or any of the banks entity names.
  //   username: the username to test.
  //   password: the password to test.
  // }
  const [credentialsToVerify, setCredentialsToVerify] = useState({});

  const [validatedCredentials, setValidatedCredentials] = useState({});

  // optionsValues will contain the response of
  // the backend endpoint '/estado-situacion-values'.
  const [optionsValues, setOptionsValues] = useState(null);

  async function fetchOptionsValues() {
    try {
      const response = await fetch(BACKEND_URL + '/estado-situacion-values', {
        method: 'GET'
      });
      if (response.ok) {
        const data = await response.json();
        setOptionsValues(data);
      } else throw new Error('/estado-situacion-values response returned, but was not ok.');
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchOptionsValues();
  }, []);

  /**
   * Sets userData to a new state, only changing the specified property.
   * @param {string} propertyChain The chain of properties to reach the property that
   * we want to update (e.g. `"occupational_info.company.type"`).
   * @param {*} value The new value of the property.
   */
  const updateUserData = (propertyChain, value) => {
    let [firstProp, ...restProps] = propertyChain.split('.');
    let objectRef = userData;
    while (restProps.length > 0) {
      objectRef = objectRef[firstProp];
      [firstProp, ...restProps] = restProps;
    }
    objectRef[firstProp] = value;
    setUserData(userData);
  };

  // Common information that show some of the views.
  const commonBannerProps = {
    title: 'Información personal',
    text: `Completa el formulario con tu información personal
    para poder rellenar tu estado de situación.`,
    Icon: IdentificationIcon
  };

  // Options for the bank SelectionStep and the BankSection component.
  const bankOptions = [
    ['banco-santander', 'Santander', SantanderIcon],
    ['banco-chile', 'Banco de Chile', ChileIcon],
    ['banco-estado', 'Banco Estado', EstadoIcon],
    ['banco-bci', 'Banco BCI', BCIIcon],
    ['banco-scotiabank', 'Banco Scotia', ScotiaIcon],
    ['banco-itau', 'Banco Itaú', ItauIcon],
    // Disabled banks:
    ['banco-falabella', 'Falabella', FalabellaGreyIcon, true],
    ['banco-bice', 'Banco Bice', BiceGreyIcon, true],
    ['banco-security', 'Security', SecurityGreyIcon, true],
    ['banco-consorcio', 'Consorcio', ConsorcioGreyIcon, true],
    ['banco-ripley', 'Banco Ripley', RipleyGreyIcon, true]
  ];

  return (
    <UserDataContext.Provider
      value={{
        userData,
        setUserData,
        credentialsToVerify,
        setCredentialsToVerify,
        validatedCredentials,
        setValidatedCredentials
      }}
    >
      <BrowserRouter basename={PUBLIC_URL}>
        <PageHeader sectionsOrder={sectionsOrder} noProgressBarPaths={noProgressBarPaths} />
        <Routes>
          <Route path={PATHS.CONTACT_DATA} element={<ContactDataSection />} />
          <Route
            path={PATHS.MARITAL_STATUS}
            element={
              <SelectionStep
                /* Use the "key" attribute to avoid rendering problems
                when navigating to a path using the same component with
                different attributes. */
                key={PATHS.MARITAL_STATUS}
                defaultNextPath={PATHS.DEPENDENTS_NUMBER}
                previousPath={PATHS.CONTACT_DATA}
                bannerProps={commonBannerProps}
                selectProps={{
                  title: 'Estado Civil',
                  options: [
                    ['single', 'Soltero', 'noto:man-medium-skin-tone-curly-hair'],
                    [
                      { value: 'married', nextPath: PATHS.MATRIMONIAL_REGIME },
                      'Casado',
                      'noto:ring'
                    ],
                    ['divorced', 'Divorciado', 'noto:clipboard'],
                    ['widowed', 'Viudo', 'noto:bouquet'],
                    [
                      { value: 'civil-partner', nextPath: PATHS.MATRIMONIAL_REGIME },
                      'Conviviente civil',
                      'noto:handshake-light-skin-tone-medium-light-skin-tone'
                    ]
                  ]
                }}
                onSelect={(value) => updateUserData('personal_info.marital_status', value)}
              />
            }
          />
          <Route
            path={PATHS.MATRIMONIAL_REGIME}
            element={
              <SelectionStep
                key={PATHS.MATRIMONIAL_REGIME}
                defaultNextPath={PATHS.DEPENDENTS_NUMBER}
                previousPath={PATHS.MARITAL_STATUS}
                bannerProps={commonBannerProps}
                selectProps={{
                  title: 'Regimen matrimonial',
                  options: [
                    ['separate-assets', 'Bienes separados', 'noto:man-medium-skin-tone-curly-hair'],
                    [
                      'communal-assets',
                      'Bienes mancomunados',
                      'noto:woman-and-man-holding-hands-medium-light-skin-tone-medium-skin-tone'
                    ],
                    [
                      'gains-participation',
                      'Participación en los gananciales',
                      'noto:house-with-garden'
                    ]
                  ]
                }}
                onSelect={(value) => updateUserData('personal_info.matrimonial_regime', value)}
              />
            }
          />
          <Route path={PATHS.DEPENDENTS_NUMBER} element={<DependentsSection />} />
          <Route
            path={PATHS.DWELLING}
            element={
              <SelectionStep
                key={PATHS.DWELLING}
                defaultNextPath={PATHS.EDUCATIONAL_LEVEL}
                previousPath={PATHS.DEPENDENTS_NUMBER}
                bannerProps={commonBannerProps}
                selectProps={{
                  title: 'Vivienda donde habita',
                  options: [
                    ['rented', 'Arrendada', 'noto:house'],
                    ['own-with-debts', 'Propia con deuda', 'noto:house-with-garden'],
                    ['own-with-no-debts', 'Propia sin deuda', 'noto:castle'],
                    ['lives-with-relatives', 'Vive con familiares', 'noto:luggage'],
                    [
                      'spouse-owned',
                      'Del cónyuge',
                      'noto:couple-with-heart-woman-man-medium-dark-skin-tone-light-skin-tone'
                    ],
                    ['fiscal', 'Fiscal', 'noto:military-helmet'],
                    ['other', 'Otro tipo de residencia', 'noto:hut']
                  ]
                }}
                onSelect={(value) => updateUserData('personal_info.dwelling', value)}
              />
            }
          />
          <Route
            path={PATHS.EDUCATIONAL_LEVEL}
            element={
              <SelectionStep
                key={PATHS.EDUCATIONAL_LEVEL}
                defaultNextPath={PATHS.JOB_SITUATION}
                previousPath={PATHS.DWELLING}
                bannerProps={commonBannerProps}
                selectProps={{
                  title: 'Nivel de estudios',
                  options: [
                    ['primary', 'Educación básica', 'noto:backpack'],
                    ['secondary', 'Educación media', 'noto:school'],
                    ['technic', 'Técnico', 'noto:hammer-and-wrench'],
                    ['graduate', 'Profesional', 'noto:graduation-cap'],
                    ['post-graduate', 'Postgrado', 'noto:open-book']
                  ]
                }}
                onSelect={(value) => updateUserData('occupational_info.educational_level', value)}
              />
            }
          />
          <Route
            path={PATHS.JOB_SITUATION}
            element={
              <SelectionStep
                key={PATHS.JOB_SITUATION}
                defaultNextPath={PATHS.PROFESSION}
                previousPath={PATHS.EDUCATIONAL_LEVEL}
                bannerProps={commonBannerProps}
                selectProps={{
                  title: 'Situación laboral',
                  options: [
                    ['unemployed', 'Desempleado', 'noto:newspaper'],
                    ['independent', 'Independiente', 'noto:t-shirt'],
                    ['dependent', 'Dependiente', 'noto:necktie']
                  ]
                }}
                onSelect={(value) => updateUserData('occupational_info.job_situation', value)}
              />
            }
          />
          <Route path={PATHS.PROFESSION} element={<ProfessionSection />} />
          <Route
            path={PATHS.SELECT_BANK}
            element={
              <SelectionStep
                key={PATHS.SELECT_BANK}
                defaultNextPath={PATHS.BANK}
                previousPath={PATHS.PROFESSION}
                bannerProps={{
                  title: 'Situación financiera',
                  text: 'Para acceder a tu información financiera nos conectaremos con tu entidad bancaria.',
                  Icon: LibraryIcon
                }}
                selectProps={{
                  title: 'Selecciona tu banco',
                  minColWidth: '130px',
                  options: bankOptions
                }}
                onSelect={(value) => updateUserData('bank_name', value)}
              />
            }
          />
          <Route path={PATHS.BANK} element={<BankSection bankOptions={bankOptions} />} />
          <Route
            path={PATHS.BANK_VALIDATION}
            element={
              <ValidationSection
                entityType={EntityTypes.BANK}
                previous={PATHS.BANK}
                next={PATHS.CLAVE_UNICA}
                loaderProps={{
                  title: 'Validando credenciales bancarias',
                  text: 'En lo que te sirvas un café, estará todo listo',
                  fakeDuration: 100 * 1000,
                  maxFakeProgress: 90,
                  steps: {
                    0: ['noto:gear', 'Iniciando proceso de consulta'],
                    10: ['noto:classical-building', 'Conectándonos a la entidad'],
                    30: ['noto:detective', 'Validando tu identidad'],
                    75: ['noto:clipboard', 'Comprobando datos'],
                    90: ['noto:alarm-clock', 'Ups! esto nos está tomando más tiempo de lo normal']
                  }
                }}
              />
            }
          />
          <Route path={PATHS.CLAVE_UNICA} element={<ClaveUnicaSection />} />
          <Route
            path={PATHS.CLAVE_UNICA_VALIDATION}
            element={
              <ValidationSection
                entityType={EntityTypes.CLAVE_UNICA}
                previous={PATHS.CLAVE_UNICA}
                next={PATHS.DATA_CONFIRMATION}
                loaderProps={{
                  title: 'Validando tu Clave Única',
                  text: 'En lo que te sirvas un café, estará todo listo',
                  fakeDuration: 75 * 1000,
                  maxFakeProgress: 90,
                  steps: {
                    0: ['noto:gear', 'Iniciando proceso de consulta'],
                    10: ['noto:link', 'Conectándonos a Clave Única'],
                    30: ['noto:detective', 'Validando tu identidad'],
                    75: ['noto:clipboard', 'Comprobando datos'],
                    90: ['noto:alarm-clock', 'Ups! esto nos está tomando más tiempo de lo normal']
                  }
                }}
              />
            }
          />
          <Route
            path={PATHS.DATA_CONFIRMATION}
            element={<ConfirmationSection optionsValues={optionsValues} />}
          />
          <Route path={PATHS.EXCEL_DOWNLOAD} element={<DownloadSection />} />
        </Routes>
      </BrowserRouter>
    </UserDataContext.Provider>
  );
}

export default App;
