/**
 * Tests if a string is not empty.
 * It might seem overkill to do this, but given how much !== ''
 * is used to test an empty string it helps to tidy things up.
 * @param {string} str - The string to test.
 * @returns {boolean} - true if str is not empty.
 */
export function isNotEmpty(str) {
  return str !== '';
}
