import styles from './index.module.css';
import { InformationCircleIcon } from '@heroicons/react/outline';

/**
 * Renders a banner component.
 * @param {object} props The props of the component.
 * @param {string} [props.className] Additional class names to add to the component.
 * @param {string} [props.title] The title of the banner.
 * @param {string} [props.text] The text of the banner.
 * @param {function} [props.Icon] The component to use as the icon of the banner.
 * @param {string} [props.iconTitle] Text for the title attribute of the icon.
 * @param {object} [props.style] Inline styles for the outermost element.
 */
const InformationalBanner = ({
  className = '',
  title = 'Información',
  text = 'Detalle de la información (texto por defecto).',
  Icon = InformationCircleIcon,
  iconTitle,
  style
}) => {
  return (
    <section className={styles.container + ' ' + className} style={style}>
      <Icon title={iconTitle} className={styles.icon} />
      <div className={styles.text}>
        <h2 className={styles.title}>{title}</h2>
        <p>{text}</p>
      </div>
    </section>
  );
};

export default InformationalBanner;
