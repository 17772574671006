import styles from './index.module.css';
import { Icon } from '@iconify/react';

/**
 * Renders a component for selecting one of the given choices.
 * @param {object} props The props of the component.
 * @param {string} [props.title] The title shown at the top.
 * @param {[*, string, string | function, boolean?][]} [props.options] The configuration
 * for each option, where each array should have: value, name, "noto" icon string or
 * icon component, and optionally a boolean indicating if the option should be disabled.
 * @param {string} [props.className] Additional classes for the component.
 * @param {object} [props.style] Inline styles for the outermost element.
 * @param {string} [props.minColWidth] Minimum column width for the grid of buttons.
 * @param {function} [props.onSelect] A function to execute when a button is
 * clicked, receives the selected value.
 * @returns
 */
const ButtonSelect = ({
  title = 'Título',
  options = [
    ['first', 'Primero', 'noto:man-medium-skin-tone-curly-hair'],
    ['second', 'Segundo', 'noto:ring'],
    ['third', 'Tercero', 'noto:clipboard']
  ],
  onSelect,
  className = '',
  style,
  minColWidth = '164px'
}) => {
  return (
    <section
      className={styles.container + ' ' + className}
      style={{ '--min-col-width': minColWidth, ...style }}
    >
      <h3 className={styles.title}>{title}</h3>
      {options.map(([value, name, OptionIcon, disabled]) => (
        <button
          onClick={onSelect && (() => onSelect(value))}
          // Use JSON.stringify to differentiate between objects, this shouldn't
          // be a problem as we don't expect large objects.
          key={typeof value === 'object' ? JSON.stringify(value) : value}
          disabled={disabled}
        >
          {typeof OptionIcon === 'string' ? (
            <Icon width={48} height={48} icon={OptionIcon} />
          ) : (
            <OptionIcon className={styles.icon} />
          )}{' '}
          {name}
        </button>
      ))}
    </section>
  );
};

export default ButtonSelect;
