import { UserDataContext, PATHS } from '../../App';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { validate as validateRut } from 'rut.js';
import { useNavigate } from 'react-router-dom';
import InformationalBanner from '../InformationalBanner';
import { Icon } from '@iconify/react';
import InputWrapper from '../InputWrapper';
import inputWrapperStyles from '../InputWrapper/index.module.css';
import styles from './index.module.css';

function ContactDataSection() {
  const { userData, setUserData } = useContext(UserDataContext);
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, isValid }
  } = useForm({ mode: 'onChange' });
  const navigate = useNavigate();
  const onSubmit = (data) => {
    setUserData({
      ...userData,
      default_username: data.rut,
      personal_info: {
        ...userData.personal_info,
        name: data.name,
        email: data.email,
        rut: data.rut
      }
    });
    navigate(PATHS.MARITAL_STATUS);
  };
  const { name, rut, email } = userData.personal_info;
  const trimValue = (value) => value.trim();

  return (
    <section className={styles.container}>
      <InformationalBanner
        title="Datos de contacto"
        text={`Ingresa tus datos de contacto, estos
               serán añadidos al archivo generado.`}
        Icon={(props) => (
          <Icon
            {...props}
            // Override the height/width styles for this icon
            // instead of using the ones defined in InformationalBanner.
            style={{
              width: '48px',
              height: '48px'
            }}
            icon="ic:outline-email"
          />
        )}
      />

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper
          className={styles.inputWrapper}
          label="¿Cuál es tu nombre?"
          showError={formErrors.name?.message}
          errorMessage={formErrors.name?.message}
        >
          <input
            className={inputWrapperStyles.input}
            type="text"
            defaultValue={name}
            placeholder="Ingresa tu nombre"
            {...register('name', {
              required: 'Se requiere ingresar un nombre.',
              setValueAs: trimValue
            })}
          />
        </InputWrapper>

        <InputWrapper
          className={styles.inputWrapper}
          label="Ingresa tu RUT"
          showError={formErrors.rut?.message}
          errorMessage={formErrors.rut?.message}
        >
          <input
            className={inputWrapperStyles.input}
            type="text"
            defaultValue={rut}
            placeholder="12.345.678-9"
            {...register('rut', {
              required: 'Se requiere ingresar un RUT.',
              setValueAs: trimValue,
              validate: (value) => validateRut(value) || 'RUT no tiene un formato correcto.'
            })}
          />
        </InputWrapper>

        <InputWrapper
          className={styles.inputWrapper}
          label="Ingresa tu Email"
          showError={formErrors.email?.message}
          errorMessage={formErrors.email?.message}
        >
          <input
            className={inputWrapperStyles.input}
            type="email"
            defaultValue={email}
            placeholder="ejemplo@email.com"
            {...register('email', {
              required: 'Se requiere ingresar un email.',
              setValueAs: trimValue
            })}
          />
        </InputWrapper>

        <button className={styles.button + ' button'} disabled={!isValid || !isDirty}>
          Confirmar mis datos
        </button>
      </form>
    </section>
  );
}

export default ContactDataSection;
