import NavigationButtons from '../NavigationButtons';
import InformationalBanner from '../InformationalBanner';
import styles from './index.module.css';
import { DocumentReportIcon } from '@heroicons/react/outline';
import { PATHS, UserDataContext } from '../../App';
import { useContext } from 'react';
import { format as formatRut } from 'rut.js';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

function displayValue(value, dictionary, isRut = false) {
  if (value === undefined || value === '') return 'No ingresado';
  if (isRut) return formatRut(value);
  return dictionary ? dictionary[value] : value;
}

const DownloadSection = ({ optionsValues }) => {
  const { userData } = useContext(UserDataContext);
  const { personal_info, occupational_info } = userData;

  // name, value, icon, and value to screen name mapping
  // for each field.
  const fields = [
    ['Nombre', personal_info.name, 'noto:man-medium-skin-tone-curly-hair'],
    ['RUT', personal_info.rut, 'noto:identification-card'],
    ['Email', personal_info.email, 'noto:envelope'],
    ['Estado civil', personal_info.marital_status, 'noto:ring', optionsValues?.['marital_status']],
    [
      'Regimen matrimonial',
      personal_info.matrimonial_regime,
      'noto:house-with-garden',
      optionsValues?.['matrimonial_regime']
    ],
    ['Tipo de vivienda', personal_info.dwelling, 'noto:house', optionsValues?.['dwelling']],
    ['Cargas familiares', personal_info.dependents_number, 'noto:family-man-girl-boy'],
    [
      'Nivel de estudios',
      occupational_info.educational_level,
      'noto:graduation-cap',
      optionsValues?.['educational_level']
    ],
    [
      'Situación laboral',
      occupational_info.job_situation,
      'noto:t-shirt',
      optionsValues?.['job_situation']
    ],
    [
      'Título, profesión o carrera',
      occupational_info.profession,
      'noto:man-construction-worker-medium-skin-tone'
    ],
    ['Rubro laboral', occupational_info.company.type, 'noto:briefcase']
  ];

  return (
    <section className={styles.container}>
      <NavigationButtons className={styles.navigationButtons} previousPath={PATHS.CLAVE_UNICA} />

      <InformationalBanner
        title="Estado de situación"
        text={`Ya puedes descargar tu estado de situación,
               a continuación tienes un resumen de tus datos personales,
               en caso de ser necesario, puedes modificarlos en el excel descargado.`}
        Icon={DocumentReportIcon}
      />

      <h2 className={styles.fieldsTitle}>Mi información</h2>

      <dl className={styles.fields}>
        {fields.map(([name, value, icon, dictionary]) => (
          <div className={styles.field} key={name}>
            <Icon width={48} height={48} className={styles.fieldIcon} icon={icon} />
            <div>
              <dt>{name}</dt>
              <dd>{displayValue(value, dictionary, name === 'RUT')}</dd>
            </div>
          </div>
        ))}
      </dl>

      <Link to={PATHS.EXCEL_DOWNLOAD} className={styles.button + ' button'}>
        Descargar estado de situación
      </Link>
    </section>
  );
};

export default DownloadSection;
