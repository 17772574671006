import NavigationButtons from '../NavigationButtons';
import styles from './index.module.css';
import { EmojiSadIcon, FolderDownloadIcon } from '@heroicons/react/outline';
import { PATHS, UserDataContext } from '../../App';
import ImageWithMessage from '../ImageWithMessage';
import { useContext, useState, useEffect } from 'react';
import Loader from '../Loader';
import { Link } from 'react-router-dom';
import Alert, { AlertType } from '../Alert';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function downloadFile(filename, content, mimeType) {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${mimeType};base64,${content}`);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

/**
 * Formats a date to YYYY-MM-DD_HH.mm.ss.
 * @param {Date} date The date to format.
 */
function formatDate(date) {
  const padNumber = (number) => (number + '').padStart(2, '0');
  const year = date.getFullYear();
  const month = padNumber(date.getMonth() + 1);
  const day = padNumber(date.getDate());
  const hour = padNumber(date.getHours());
  const minute = padNumber(date.getMinutes());
  const second = padNumber(date.getSeconds());
  return `${year}-${month}-${day}_${hour}.${minute}.${second}`;
}

/**
 * Formats a name to all caps, replacing spaces
 * with underscores.
 * @param {string} name The name to format.
 */
function formatName(name) {
  return name.trim().toUpperCase().replaceAll(' ', '_');
}

const DownloadSection = () => {
  const { userData } = useContext(UserDataContext);
  const config = { user_data: userData, return_on_api_error: false };
  const [isFetching, setIsFetching] = useState(false);
  const [showFetchingError, setShowFetchingError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [unavailableActions, setUnavailableActions] = useState({});
  const [apiErrors, setApiErros] = useState({});
  const requestFile = async () => {
    setIsFetching(true);
    setShowFetchingError(false);
    setUnavailableActions({});
    setShowSuccessMessage(false);
    try {
      const response = await fetch(BACKEND_URL + '/estado-situacion', {
        method: 'POST',
        body: JSON.stringify(config),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const { data, content_type, unavailable_actions, api_errors } = await response.json();
        setApiErros(api_errors);
        setUnavailableActions(unavailable_actions);
        const name = formatName(userData.personal_info.name);
        const date = formatDate(new Date());
        const fileName = `estado_situacion_${name}_${date}.xlsx`;
        downloadFile(fileName, data, content_type);
        setShowSuccessMessage(true);
      } else throw new Error('/estado-situacion response returned, but was not ok.');
    } catch (error) {
      console.log(error);
      setShowFetchingError(true);
    } finally {
      setIsFetching(false);
    }
  };

  // Request the file at the first render.
  useEffect(() => {
    requestFile();
    // eslint-disable-next-line
  }, []);

  const { bank_name } = userData;
  const entityActions = {
    banco: `${bank_name}:income-report`,
    SII: 'sii:tax-folder',
    CMF: 'cmf:debt',
    AFC: 'afc:all'
  };
  const entitiesWithIssues = [];
  for (const [entity, action] of Object.entries(entityActions)) {
    if (action in unavailableActions || action in apiErrors) {
      entitiesWithIssues.push(entity);
    }
  }
  const excelIssues =
    entitiesWithIssues.length !== 0
      ? `Hubo problemas al obtener la información de: ${
          // Replace the last ', ' with ' y '.
          entitiesWithIssues
            .join(', ')
            .split('')
            .reverse()
            .join('')
            .replace(' ,', ' y ')
            .split('')
            .reverse()
            .join('')
        }.`
      : '';

  const showBankSteps = userData.bank_password !== undefined;
  const showClaveUnicaSteps = userData.clave_unica_password !== undefined;
  // Set the amount of seconds for expecting the queries
  // to be done.
  const bankStepsTime = showBankSteps ? 40 : 0;
  const claveUnicaStepsTime = showClaveUnicaSteps ? 40 : 0;

  const loaderTitle = 'Generando archivo';
  const loaderText = 'En lo que te sirvas un café, estará todo listo';

  return (
    <section className={styles.container}>
      <NavigationButtons
        className={styles.navigationButtons}
        previousPath={PATHS.DATA_CONFIRMATION}
      />

      {isFetching && (
        <Loader
          title={loaderTitle}
          text={loaderText}
          className={styles.loader}
          maxFakeProgress={90}
          fakeDuration={(30 + bankStepsTime + claveUnicaStepsTime) * 1000}
          steps={{
            0: ['noto:gear', 'Iniciando proceso de consulta'],
            10: ['noto:classical-building', 'Conectándonos a las entidades'],
            ...(showBankSteps
              ? {
                  20: ['noto:dollar-banknote', 'Calculando tu sueldo'],
                  30: ['noto:money-with-wings', 'Buscando ingresos extra']
                }
              : null),
            ...(showClaveUnicaSteps
              ? {
                  40: ['noto:classical-building', 'Analizando reporte de deudas'],
                  50: ['noto:credit-card', 'Obteniendo información de créditos'],
                  60: ['noto:money-bag', 'Analizando reporte tributario'],
                  70: ['noto:handshake', 'Obteniendo información de sociedades'],
                  75: ['noto:houses', 'Obteniendo información de propiedades']
                }
              : null),
            80: ['noto:clipboard', 'Comprobando datos'],
            90: ['noto:alarm-clock', 'Ups! esto nos está tomando más tiempo de lo normal']
          }}
        />
      )}

      {!isFetching && (
        <ImageWithMessage className={styles.imgWithMessage} title={loaderTitle} text={loaderText} />
      )}

      {showSuccessMessage && (
        <Alert Icon={FolderDownloadIcon} type={AlertType.SUCCESS} className={styles.alert}>
          Archivo descargado exitosamente.
        </Alert>
      )}

      {showFetchingError && (
        <Alert Icon={EmojiSadIcon} type={AlertType.ERROR} className={styles.alert}>
          Lo sentimos, ha ocurrido un error al intentar realizar la descarga.
        </Alert>
      )}

      {excelIssues && (
        <Alert Icon={EmojiSadIcon} type={AlertType.ALERT} className={styles.alert}>
          {excelIssues}
        </Alert>
      )}

      <div className={styles.buttons}>
        <button disabled={isFetching} onClick={requestFile} className={styles.button + ' button'}>
          Volver a intentar la descarga
        </button>
        <Link className={styles.button + ' button button_outline'} to="/" reloadDocument={true}>
          Volver al inicio
        </Link>
      </div>
    </section>
  );
};

export default DownloadSection;
