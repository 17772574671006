import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './index.css';

/**
 * Renders the header of the site, with a progress bar.
 * The progress shown in the progress bar depends on the current path
 * and its position in the given `sectionsOrder` prop.
 * @param {object} props - The props of the component.
 * @param {string[]} props.sectionsOrder - The paths of the sections in the order they should be visited.
 * @param {string[]} props.noProgressBarPaths - The paths for which the progress bar should be hidden.
 */
function PageHeader({ sectionsOrder, noProgressBarPaths }) {
  // Progress should be a number from 0 to 100.
  const [progress, setProgress] = useState(0);
  const [isProgressHidden, setIsProgressHidden] = useState(false);
  let location = useLocation();

  useEffect(() => {
    // Change progress depending on location.
    const totalSteps = sectionsOrder.length;
    const stepSize = 100 / totalSteps;
    const currentStep = sectionsOrder.indexOf(location.pathname) + 1;
    setProgress(currentStep * stepSize);

    // Hide the progress bar when we are in one of this paths.
    setIsProgressHidden(noProgressBarPaths.includes(location.pathname));
  }, [location, sectionsOrder, noProgressBarPaths]);

  return (
    <header className="page-header">
      <h1 className="page-header__title">
        Estado de Situación
        <span className="page-header__title-beta">Beta</span>
      </h1>
      {!isProgressHidden && (
        <div className="page-header__progress-meter">
          <div className="page-header__progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      )}
    </header>
  );
}

export default PageHeader;
