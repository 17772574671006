import { UserDataContext, PATHS } from '../../App';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { isNotEmpty } from '../../helpers';
import BankCredentialsModal from '../BankCredentialsModal';
import NavigationButtons from '../NavigationButtons';
import InformationalBanner from '../InformationalBanner';
import LockedRutInput from '../LockedRutInput';
import PasswordInput from '../PasswordInput';
import { EntityTypes } from '../ValidationSection';
import styles from './index.module.css';

/**
 * Renders a view that asks the user for the password of
 * the selected bank.
 * @param {{
 *  bankOptions: [string, string, function][]
 * }} props The props of the component. Includes:
 * - `bankOptions`: An array of 3-tuples containing the bank name/id, human name
 * and icon component for each bank.
 * @returns
 */
function BankSection({ bankOptions }) {
  const { userData, setCredentialsToVerify, validatedCredentials, setValidatedCredentials } =
    useContext(UserDataContext);
  const { bank_username, default_username, bank_name, bank_password } = userData;
  const effectiveUsername = bank_username || default_username;
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    setCredentialsToVerify({
      entity: bank_name,
      password: data.bankPassword,
      username: effectiveUsername
    });
    checkBankValidation();
    navigate(PATHS.BANK_VALIDATION);
  };
  const [isPasswordSet, setIsPasswordSet] = useState(isNotEmpty(bank_password ?? ''));
  const [showModal, setShowModal] = useState(false);

  const [, bankScreenName, BankIcon] = bankOptions.find(([bankName]) => bankName === bank_name);

  const checkBankValidation = () => {
    const { is_validated, bank_name: validatedBankName } =
      validatedCredentials[EntityTypes.BANK] || {};
    if (is_validated && validatedBankName !== bank_name) {
      setValidatedCredentials((prev) => ({
        ...prev,
        [EntityTypes.BANK]: {}
      }));
    }
  };

  return (
    <>
      <BankCredentialsModal isOpen={showModal} close={() => setShowModal(false)} />
      <section className={styles.container}>
        <NavigationButtons
          className={styles.navigationButtons}
          previousPath={PATHS.SELECT_BANK}
          nextPath={PATHS.CLAVE_UNICA}
        />

        <InformationalBanner
          title="Ingresa tus credenciales"
          text={`Para acceder a tu información financiera nos conectaremos
                 con tu entidad bancaria. No te preocupes, no guardamos ni
                 tu clave ni tu información personal.`}
          Icon={BankIcon}
          iconTitle={bankScreenName}
        />

        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <LockedRutInput className={styles.rut} rut={effectiveUsername} />

          <PasswordInput
            inputProps={{
              placeholder: 'Ingresa tu clave de acceso',
              defaultValue: bank_password,
              ...register('bankPassword', {
                onChange: (event) => setIsPasswordSet(isNotEmpty(event.target.value))
              })
            }}
            inputWrapperProps={{
              label: 'Ingresa tu clave bancaria',
              showError: formErrors.bankPassword?.message,
              errorMessage: formErrors.bankPassword?.message
            }}
          />

          <button type="button" onClick={() => setShowModal(true)} className={styles.modalButton}>
            ¿Por qué solicitamos tu clave bancaria?
          </button>

          <button disabled={!isPasswordSet} className={styles.button + ' button'}>
            Validar mi identidad
          </button>
        </form>
      </section>
    </>
  );
}

export default BankSection;
