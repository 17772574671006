import { UserDataContext, PATHS } from '../../App';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import InputWrapper from '../InputWrapper';
import InformationalBanner from '../InformationalBanner';
import NavigationButtons from '../NavigationButtons';
import { IdentificationIcon } from '@heroicons/react/outline';
import inputWrapperStyles from '../InputWrapper/index.module.css';
import styles from './index.module.css';

function ProfessionSection() {
  const { userData, setUserData } = useContext(UserDataContext);
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, isValid }
  } = useForm({ mode: 'onChange' });

  const navigate = useNavigate();
  const onSubmit = (data) => {
    setUserData({
      ...userData,
      occupational_info: {
        ...userData.occupational_info,
        profession: data.profession,
        company: {
          ...userData.occupational_info?.company,
          type: data.jobType
        }
      }
    });
    navigate(PATHS.SELECT_BANK);
  };

  const {
    occupational_info: {
      profession,
      company: { type: job_type }
    }
  } = userData;
  const trimValue = (value) => value.trim();

  return (
    <section className={styles.container}>
      <NavigationButtons className={styles.navigationButtons} previousPath={PATHS.JOB_SITUATION} />

      <InformationalBanner
        title="Información personal"
        text={`Completa el formulario con tu información personal
               para poder rellenar tu estado de situación.`}
        Icon={IdentificationIcon}
      />

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper
          className={styles.inputWrapper}
          label="Título, profesión o carrera"
          showError={formErrors.profession?.message}
          errorMessage={formErrors.profession?.message}
        >
          <input
            className={inputWrapperStyles.input}
            type="text"
            placeholder="Ingresa el nombre de tu profesión"
            defaultValue={profession}
            {...register('profession', {
              setValueAs: trimValue,
              required: true
            })}
          />
        </InputWrapper>

        <InputWrapper
          className={styles.inputWrapper}
          label="Rubro en el que trabajas"
          showError={formErrors.email?.message}
          errorMessage={formErrors.email?.message}
        >
          <input
            className={inputWrapperStyles.input}
            type="text"
            placeholder="Ingresa el rubro en el cual trabajas"
            defaultValue={job_type}
            {...register('jobType', {
              setValueAs: trimValue,
              required: true
            })}
          />
        </InputWrapper>

        <button className={styles.button + ' button'} disabled={!isValid || !isDirty}>
          Continuar
        </button>
      </form>
    </section>
  );
}

export default ProfessionSection;
