import { ChevronLeftIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import styles from './index.module.css';

const NavigationButtons = ({ previousPath, className }) => {
  return (
    <div className={styles.container + ' ' + className}>
      {previousPath && (
        <Link to={previousPath} className={styles.previous}>
          <ChevronLeftIcon className={styles.icon} /> Paso anterior
        </Link>
      )}
    </div>
  );
};

export default NavigationButtons;
