import styles from './index.module.css';

export const AlertType = {
  ALERT: styles.alert,
  ERROR: styles.error,
  SUCCESS: styles.success
};

/**
 * Renders an alert message, with an icon.
 * @param {{
 *  type?: string,
 *  Icon?: function,
 *  className?: string,
 * }} props The props of the component. Includes:
 * - `type`: The type of alert to show.
 * - `Icon`: The icon to show at the left side.
 * - `className`: Additional classes for the outermost element.
 * - `children`: The content of the alert.
 * @returns
 */
const Alert = ({ type = AlertType.ALERT, Icon, children, className = '' }) => {
  return (
    <strong className={styles.container + ' ' + type + ' ' + className}>
      {Icon && <Icon className={styles.icon} />} {children}
    </strong>
  );
};

export default Alert;
