import { UserDataContext } from '../../App';
import { useContext, useState, useEffect } from 'react';
import Loader from '../Loader';
import { Link } from 'react-router-dom';
import Alert, { AlertType } from '../Alert';
import ImageWithMessage from '../ImageWithMessage';
import { CheckCircleIcon, EmojiSadIcon } from '@heroicons/react/outline';
import styles from './index.module.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

/**
 * Values allowed to pass as the `entityType` prop for
 * the `ValidationSection` component.
 */
export const EntityTypes = {
  BANK: 'bank',
  CLAVE_UNICA: 'clave_unica',
  SII: 'sii'
};

/**
 * Renders a loading view for the user to wait while validating a
 * set of credentials.
 * @param {object} props The props of the component.
 * @param {string} props.entityType The type of entity for which to validate the credentials
 * Should be one of the values in `EntityTypes`.
 * @param {string} props.previous The previous path to which the component should send the user
 * in case the credentials are wrong and need to be edited.
 * @param {string} props.next The next path to send the user to in case the validation is ok.
 * @param {object} props.loaderProps The props to pass to the `Loader` component
 * @returns
 */
function ValidationSection({ entityType, previous, next, loaderProps }) {
  const entitiesValues = Object.values(EntityTypes);
  if (!entitiesValues.includes(entityType)) {
    throw new Error(`entityType should be one of ${entitiesValues.join(', ')}, got ${entityType}.`);
  }
  const { userData, setUserData, validatedCredentials, setValidatedCredentials } =
    useContext(UserDataContext);
  const { credentialsToVerify } = useContext(UserDataContext);
  const [isFetching, setIsFetching] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isIdentityValid, setIsIdentityValid] = useState(false);

  async function validateCredentials() {
    try {
      // check if entity is already validated to avoid validate it again
      const { is_validated, username, password } = validatedCredentials[entityType] || {};
      if (
        is_validated &&
        username === credentialsToVerify.username &&
        password === credentialsToVerify.password
      ) {
        setIsIdentityValid(true);
        return;
      }
      const response = await fetch(BACKEND_URL + '/identity-validation', {
        method: 'POST',
        body: JSON.stringify(credentialsToVerify),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) throw new Error('/identity-validation response returned, but was not ok.');
      const { is_valid, error } = await response.json();
      if (error) {
        setErrorMessage(error);
        return;
      }
      setIsIdentityValid(is_valid);
      if (is_valid) {
        // Save the credentials with the rest of the user's data.
        const credentials = {
          [`${entityType}_password`]: credentialsToVerify.password,
          [`${entityType}_username`]: credentialsToVerify.username
        };
        if (entityType === EntityTypes.BANK) credentials.bank_name = credentialsToVerify.entity;
        setUserData({
          ...userData,
          ...credentials
        });
        setValidatedCredentials((prev) => ({
          ...prev,
          [entityType]: {
            is_validated: true,
            bank_name: entityType === EntityTypes.BANK ? credentials.bank_name : undefined,
            username: credentialsToVerify.username,
            password: credentialsToVerify.password
          }
        }));
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Ha ocurrido un error. No pudimos validar sus credenciales.');
    } finally {
      setIsFetching(false);
    }
  }
  useEffect(() => {
    validateCredentials();
    // We only want to run this once at the first
    // render, with the credentialsToVerify set just before
    // navigating here. That's why we use [].
    // eslint-disable-next-line
  }, []);

  const { title: loaderTitle, text: loaderText } = loaderProps;

  return (
    <section className={styles.container}>
      {/* Waiting for validation response to arrive. */}
      {isFetching && <Loader {...loaderProps} />}

      {!isFetching && (
        <ImageWithMessage className={styles.imgWithMessage} title={loaderTitle} text={loaderText} />
      )}

      {/* The credentials are valid. */}
      {!isFetching && isIdentityValid && (
        <>
          <Alert Icon={CheckCircleIcon} type={AlertType.SUCCESS} className={styles.alert}>
            Credenciales validadas.
          </Alert>
          <Link className="button" to={next}>
            Continuar
          </Link>
        </>
      )}

      {/* The credentials are wrong and need to be edited. */}
      {!isFetching && !errorMessage && !isIdentityValid && (
        <>
          <Alert Icon={EmojiSadIcon} type={AlertType.ERROR} className={styles.alert}>
            Credenciales incorrectas.
          </Alert>
          <Link className="button" to={previous}>
            Editar credenciales
          </Link>
        </>
      )}

      {/* An error has ocurred with the request. */}
      {!isFetching && errorMessage && (
        <>
          <Alert Icon={EmojiSadIcon} type={AlertType.ERROR} className={styles.alert}>
            {errorMessage}
          </Alert>
          <Link className="button" to={previous}>
            Editar credenciales
          </Link>
        </>
      )}
    </section>
  );
}

export default ValidationSection;
