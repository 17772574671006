import ModalImg from '../../assets/modal-img.svg';
import LockIcon from '../../assets/lock-icon-blue.svg';
import CoinIcon from '../../assets/coin-icon.svg';
import BankIcon from '../../assets/bank-icon.svg';
import './index.css';

// TODO: Make a component for modals, to remove duplication between
// BankCredentialsModal and GobCredentialsModal.
const BankCredentialsModal = ({ isOpen, close }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <>
      <div onClick={close} className="bank-credentials-modal__background">
        {/* Stop the propagation of click events to avoid closing the modal
            when it gets clicked. */}
        <div className="bank-credentials-modal__container" onClick={(e) => e.stopPropagation()}>
          <img src={ModalImg} className="bank-credentials-modal__image" alt="" />
          <h3 className="bank-credentials-modal__title">Seguridad y claves de acceso</h3>
          <p className="bank-credentials-modal__text">
            Esta clave es la que usas para acceder a tu portal bancario. Usamos esta clave solo para
            procesos de consulta y <strong>no guardamos ningún dato</strong>.
          </p>
          <div className="bank-credentials-modal__highlight">
            <div className="bank-credentials-modal__highlight-line">
              <img src={LockIcon} className="bank-credentials-modal__highlight-icon" alt="" />
              No guardamos tus claves
            </div>
            <div className="bank-credentials-modal__highlight-line">
              <img src={CoinIcon} className="bank-credentials-modal__highlight-icon" alt="" />
              No solicitamos claves transaccionales
            </div>
            <div className="bank-credentials-modal__highlight-line">
              <img src={BankIcon} className="bank-credentials-modal__highlight-icon" alt="" />
              No guardamos tus datos
            </div>
          </div>
          <button onClick={close} className="button bank-credentials-modal__button">
            Me queda claro
          </button>
        </div>
      </div>
    </>
  );
};
export default BankCredentialsModal;
