import ButtonSelect from '../ButtonSelect';
import InformationalBanner from '../InformationalBanner';
import { useNavigate } from 'react-router-dom';
import NavigationButtons from '../NavigationButtons';
import styles from './index.module.css';

/**
 * Renders a view for selecting a value and automatically navigating
 * to the next step/view.
 * @param {object} props The props for the component.
 * @param {string} props.defaultNextPath The default path to the next step.
 * @param {string} props.skipToPath The path to skip to, when omitting this step.
 * @param {string} props.previousPath The path of the previous step.
 * @param {{
 *  title: string,
 *  text: string
 *  Icon: function
 * }} props.bannerProps The title, text and icon component for the
 * InformationalBanner component.
 * @param {{
 *  title: string,
 *  options: [{ value: *, nextPath: string } | *, string, string][],
 *  minColWidth: string
 * }} props.selectProps The title, options and the minimum grid column width in pixels
 * for the ButtonSelect component. If the first element (value) of an option has the
 * keys `value` and `nextPath` those are used as the value and path to send the user
 * to when that option is selected.
 * @param {function} props.onSelect A function to execute when the selection is made,
 * receives the selected value.
 * @returns
 */
const SelectionStep = ({ defaultNextPath, previousPath, bannerProps, selectProps, onSelect }) => {
  const navigate = useNavigate();
  return (
    <section className={styles.container}>
      <NavigationButtons className={styles.navigationButtons} previousPath={previousPath} />

      <InformationalBanner className={styles.banner} {...bannerProps} />

      <ButtonSelect
        {...selectProps}
        className={styles.select}
        onSelect={(selection) => {
          if (typeof selection === 'object' && 'value' in selection && 'nextPath' in selection) {
            onSelect(selection.value);
            navigate(selection.nextPath);
          } else {
            onSelect(selection);
            navigate(defaultNextPath);
          }
        }}
      />
    </section>
  );
};

export default SelectionStep;
