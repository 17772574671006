import { ReactComponent as HumanSideways } from '../../assets/human-sideways.svg';
import styles from './index.module.css';

const ImageWithMessage = ({ title, text, className }) => {
  return (
    <div className={styles.container + ' ' + className}>
      <HumanSideways className={styles.img} aria-hidden="true" />
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default ImageWithMessage;
