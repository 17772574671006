import { UserDataContext, PATHS } from '../../App';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { isNotEmpty } from '../../helpers';
import GobCredentialsModal from '../GobCredentialsModal';
import InformationalBanner from '../InformationalBanner';
import PasswordInput from '../PasswordInput';
import NavigationButtons from '../NavigationButtons';
import LockedRutInput from '../LockedRutInput';
import { ReactComponent as ClaveUnicaLogo } from '../../assets/clave-unica.svg';
import styles from './index.module.css';

function ClaveUnicaSection() {
  const { userData, setCredentialsToVerify } = useContext(UserDataContext);
  const { default_username, clave_unica_username, clave_unica_password } = userData;
  const effectiveUsername = clave_unica_username || default_username;
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    setCredentialsToVerify({
      entity: 'clave_unica',
      password: data.password,
      username: default_username
    });
    navigate(PATHS.CLAVE_UNICA_VALIDATION);
  };
  const [isPasswordSet, setIsPasswordSet] = useState(isNotEmpty(clave_unica_password ?? ''));
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <GobCredentialsModal isOpen={showModal} close={() => setShowModal(false)} />
      <section className={styles.container}>
        <NavigationButtons
          className={styles.navigationButtons}
          previousPath={PATHS.SELECT_BANK}
          nextPath={PATHS.DATA_CONFIRMATION}
        />

        <InformationalBanner
          title="Validemos tu identidad"
          text={`Ingresa tus credenciales, nos conectaremos a
                 Clave Única para poder validar tu identidad.`}
          Icon={ClaveUnicaLogo}
          iconTitle={'Clave Única'}
        />

        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <LockedRutInput className={styles.rut} rut={effectiveUsername} />

          <PasswordInput
            inputProps={{
              placeholder: 'Ingresa tu clave de acceso',
              defaultValue: clave_unica_password,
              ...register('password', {
                onChange: (event) => setIsPasswordSet(isNotEmpty(event.target.value))
              })
            }}
            inputWrapperProps={{
              label: 'Ingresa tu Clave Única',
              showError: formErrors.password?.message,
              errorMessage: formErrors.password?.message
            }}
          />

          <button type="button" onClick={() => setShowModal(true)} className={styles.modalButton}>
            ¿Por qué solicitamos tu Clave Única?
          </button>

          <button disabled={!isPasswordSet} className={styles.button + ' button'}>
            Validar mi identidad
          </button>
        </form>
      </section>
    </>
  );
}

export default ClaveUnicaSection;
