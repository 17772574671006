import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import InputWrapper from '../InputWrapper';
import { useState } from 'react';
import styles from './index.module.css';

/**
 * Renders a password input using the InputWrapper component.
 * The input type can switch between "password" and "text" to show
 * the password.
 * @param {object} props The props of the component.
 * @param {object} props.inputProps The props for the input.
 * @param {object} props.inputWrapperProps The props for the InputWrapper component.
 * @returns
 */
const PasswordInput = ({ inputProps, inputWrapperProps }) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const Icon = isPasswordHidden ? EyeIcon : EyeOffIcon;
  return (
    <InputWrapper {...inputWrapperProps}>
      <div className={styles.wrapper}>
        <input
          // The order of the props here is important.
          placeholder="Ingresa tu clave"
          {...inputProps}
          type={isPasswordHidden ? 'password' : 'text'}
          className={styles.input}
        />
        <button
          className={styles.button}
          type="button"
          onClick={() => setIsPasswordHidden(!isPasswordHidden)}
          title={isPasswordHidden ? 'Muestra la clave' : 'Esconde la clave'}
        >
          <Icon className={styles.icon}></Icon>
        </button>
      </div>
    </InputWrapper>
  );
};

export default PasswordInput;
