import React, { useState, useEffect } from 'react';
import ImageWithMessage from '../ImageWithMessage';
import { Icon } from '@iconify/react';
import './index.css';

/**
 * Renders a loader that tells the current step.
 * @param {object} props The props of the component.
 * @param {string} [props.className] The classes to add to add to the component.
 * @param {string} [props.title] The title for the loader to show.
 * @param {string} [props.text] A text to show below the title.
 * @param {object} [props.steps] A mapping from progress percentages to 2-tuples of the Iconify icon
 * strings and texts to use from each mapped percentage onward.
 * @param {number} [props.progress] The percentage of progress (a number from 0 to 100),
 * ignored if `fakeDuration` is given.
 * @param {number} [props.fakeDuration] If present, makes the loading bar act as fake, ignoring `progress`
 * and setting the progress of the loading bar depending on how much time has passed to reach `fakeDuration`.
 * @param {number} [props.maxFakeProgress] Sets the maximum progress when using `fakeDuration`.
 */
const Loader = ({
  className = '',
  title = 'Validando credenciales',
  text = 'En lo que te sirvas un café, estará todo listo',
  steps = {},
  progress = 0,
  fakeDuration,
  maxFakeProgress = 100
}) => {
  const [fakeProgress, setFakeProgress] = useState(0);
  const [[stepIconStr, stepText], setStep] = useState(['noto:detective', 'Buscando tu identidad']);

  const progressToRender = fakeDuration !== undefined ? fakeProgress : progress;

  // Set the initial step at 0%.
  useEffect(() => {
    if (steps[0] && progressToRender === 0) setStep(steps[0]);
  }, [steps, progressToRender]);

  // Set a new step icon and text if available, when
  // the progress changes.
  useEffect(() => {
    if (steps[progressToRender]) setStep(steps[progressToRender]);
  }, [progressToRender, steps]);

  // Use a fake progress meter when fakeDuration
  // is given.
  useEffect(() => {
    if (fakeDuration !== undefined && fakeProgress < maxFakeProgress) {
      const id = setTimeout(() => {
        setFakeProgress(fakeProgress + 1);
      }, fakeDuration / 100);
      return () => clearTimeout(id);
    }
  }, [fakeDuration, fakeProgress, maxFakeProgress]);

  return (
    <section className={'loader ' + className}>
      <ImageWithMessage title={title} text={text} className={'loader__img'} />

      <div className="loader__progress-bar">
        <div className="loader__progress" style={{ width: progressToRender + '%' }}></div>
      </div>

      <p className="loader__step-indicator">
        <Icon width={24} height={24} icon={stepIconStr} className="loader__step-icon" />
        <span className="loader__step-text">{stepText}...</span>
      </p>
    </section>
  );
};

export default Loader;
